<template>
    <div>
        <b-card title="Enable" class="mb-2">
            <b-row class="m-0 p-0" align-v="center">
                <b-form-checkbox switch size="lg" v-model="data.section_2.enable"></b-form-checkbox>
                <div style="margin:auto;"></div>
                <b-button variant="primary" class="mt-2 float-right" @click="saveEnable">Save</b-button>
            </b-row>
        </b-card>
        <b-card title="Image">
            <b-row class="p-0 m-0">
                <NImg class="w-100 p-0 m-0" v-model="data.section_2.img" :alt="data.section_2.img" width="200" height="200"
                    @input="topImgChanged" />
            </b-row>
        </b-card>
        <b-card title="Top" class="mt-2">
            <b-form v-for="(val, key) in data.section_2.top" :key="key"
                @submit.prevent="$parent.$parent.$parent.save('section_2', 'top', key)">
                <b-form-group :label="key">
                    <b-input-group prepend="EN">
                        <b-form-input v-model="data.section_2.top[key].en" />
                    </b-input-group>
                    <b-input-group prepend="FR" class="mt-1">
                        <b-form-input v-model="data.section_2.top[key].fr" />
                    </b-input-group>
                    <b-button variant="primary" type="submit" class="mt-2 float-right">Save</b-button>
                </b-form-group>
            </b-form>
        </b-card>
        <b-card title="Side" class="mt-2">
            <b-form v-for="(val, key) in data.section_2.side" :key="key"
                @submit.prevent="$parent.$parent.$parent.save('section_2', 'side', key)">
                <b-form-group :label="key">
                    <b-input-group prepend="EN">
                        <b-form-input v-model="data.section_2.side[key].en" />
                    </b-input-group>
                    <b-input-group prepend="FR" class="mt-1">
                        <b-form-input v-model="data.section_2.side[key].fr" />
                    </b-input-group>
                    <b-button variant="primary" type="submit" class="mt-2 float-right">Save</b-button>
                </b-form-group>
            </b-form>
        </b-card>
        <b-card title="Items" class="mt-2">
            <b-card v-for="(item, index) in data.section_2.items" :key="index" class="mt-1">
                <b-row class="m-0 p-0">
                    <div style="width: 70px;height: 70px;border-radius: 8px;padding: 8px;"
                        :style="{ backgroundColor: data.themes.items[data.themes.selectedIndex].primary, color: data.themes.items[data.themes.selectedIndex].onPrimary }">
                        <b-icon :icon="item.icon" class="w-100 h-100"></b-icon>
                    </div>
                    <b-col class="py-2">
                        <h3 style="
                    font-weight: 400;
                    font-style: normal;
                    font-family: 'Titillium Web', sans-serif;
                    font-size: 23px;
                    line-height: 0.8;
                    letter-spacing: 0px;">{{ item.title.fr }}</h3>
                        <p style="
                    padding-top: 4px;
                    font-weight: 400;
                    font-style: normal;
                    font-family: 'Open Sans', sans-serif;
                    color: #828282;
                    font-size: 16px;
                    line-height: 1.4;
                    letter-spacing: 0px;">{{ item.body.fr }}</p>
                    </b-col>
                </b-row>
                <div
                    style="position: absolute;top:0;height: 100%;width: 100%;left: 0;background: #22222277;pointer-events:all;display: block;padding: 8px;">
                    <b-button class="float-right ml-1" variant="info" @click="showEditItemModal(item, index)"><b-icon
                            icon="pencil-fill" aria-label="Edit"></b-icon></b-button>
                    <b-button class="float-right" variant="danger" @click="deleteItem(index)"><b-icon icon="trash-fill"
                            aria-label="Delete"></b-icon></b-button>
                </div>
            </b-card>
            <b-col sm="12" md="" class="p-0 m-0 mt-2">
                <b-button class="w-100 h-100" variant="info" @click="showAddItemModal"><b-icon icon="plus-lg"
                        aria-label="Add"></b-icon></b-button>
            </b-col>
        </b-card>
        <b-modal id="section2ItemModal" :title="modalData.modalTitle" hide-footer>
            <b-form @submit.prevent="saveSection2Item">
                <NIcon v-model="modalData.icon" width="100px" height="100px" padding="8px"></NIcon>
                <b-form-group label="Title">
                    <b-input-group prepend="EN">
                        <b-form-input v-model="modalData.title_en" />
                    </b-input-group>
                    <b-input-group prepend="FR" class="mt-1">
                        <b-form-input v-model="modalData.title_fr" />
                    </b-input-group>
                </b-form-group>
                <b-form-group label="Body">
                    <b-input-group prepend="EN">
                        <b-form-input v-model="modalData.body_en" />
                    </b-input-group>
                    <b-input-group prepend="FR" class="mt-1">
                        <b-form-input v-model="modalData.body_fr" />
                    </b-input-group>
                </b-form-group>
                <b-button variant="primary" type="submit" class="my-2 float-right">Save</b-button>
            </b-form>
        </b-modal>
    </div>
</template>
<script>
import axios from "axios";
import { getCookie } from "../../Api";
export default {
    name: 'Section_2',
    props: ['data'],
    data() {
        return {
            modalData: {}
        }
    },
    methods: {
        async saveEnable() {
            const p = this.$parent.$parent.$parent
            let payload = {
                section: 'section_2',
                enable: this.data.section_2.enable
            }
            p.isLoading = true
            try {
                const res = await axios.post(
                    this.$baseUrl + "/api/section_enable",
                    payload,
                    {
                        headers: {
                            secret: getCookie("secret")
                        }
                    }
                );
                this.$bvToast.toast("updated", {
                    title: "Success",
                    variant: "success",
                    solid: true
                });
                p.onDataChanged(res.data)
            } catch (error) {
                p.showError(error);
            }
            p.isLoading = false
        },
        async topImgChanged(i) {
            const p = this.$parent.$parent.$parent
            const payload = { img: i, field: 'img' }
            p.isLoading = true
            try {
                const res = await axios.post(this.$baseUrl + "/api/section_2", payload, {
                    headers: {
                        secret: getCookie("secret")
                    }
                })
                this.$bvToast.toast("updated", {
                    title: "Success",
                    variant: "success",
                    solid: true
                });
                p.onDataChanged(res.data)
            }
            catch (error) {
                p.showError(error);
            }
            p.isLoading = false
        },
        async showAddItemModal() {
            this.modalData = {
                modalTitle: 'Add Item'
            }
            this.$bvModal.show('section2ItemModal')
        },
        async showEditItemModal(item, index) {
            this.modalData = {
                modalTitle: 'Edit Item',
                title_en: item.title.en,
                title_fr: item.title.fr,
                body_en: item.body.en,
                body_fr: item.body.fr,
                icon: item.icon,
                index: index
            }
            this.$bvModal.show('section2ItemModal')
        },
        async saveSection2Item() {
            const p = this.$parent.$parent.$parent
            const payload = {
                field: 'items',
                icon: this.modalData.icon,
                title_en: this.modalData.title_en,
                title_fr: this.modalData.title_fr,
                body_en: this.modalData.body_en,
                body_fr: this.modalData.body_fr,
                index: this.modalData.index
            }
            p.isLoading = true
            try {
                const res = await axios.post(
                    this.$baseUrl + "/api/section_2",
                    payload,
                    {
                        headers: {
                            secret: getCookie("secret")
                        }
                    }
                );
                this.$bvToast.toast("updated", {
                    title: "Success",
                    variant: "success",
                    solid: true
                });
                this.$bvModal.hide('section2ItemModal')
                p.onDataChanged(res.data)
            } catch (error) {
                p.showError(error);
            }
            p.isLoading = false
        },
        async deleteItem(index){
            const p = this.$parent.$parent.$parent
            if (await this.$bvModal.msgBoxConfirm('Please confirm that you want to delete this item.', {
                title: 'Please Confirm',
                size: 'lg',
                buttonSize: 'md',
                okVariant: 'danger',
                okTitle: 'DELETE',
                cancelTitle: 'CANCEL',
                footerClass: 'p-2',
                hideHeaderClose: false,
                centered: true
            })) {
                let payload = {
                    field: 'delete_item',
                    index: index
                }
                p.isLoading = true
                try {
                    const res = await axios.post(
                        this.$baseUrl + "/api/section_2",
                        payload,
                        {
                            headers: {
                                secret: getCookie("secret")
                            }
                        }
                    );
                    this.$bvToast.toast("updated", {
                        title: "Success",
                        variant: "success",
                        solid: true
                    });
                    p.onDataChanged(res.data)
                } catch (error) {
                    p.showError(error);
                }
                p.isLoading = false
            }
        }
    }
}
</script>