<template>
    <div>
        <b-card title="Enable" class="mb-2">
            <b-row class="m-0 p-0" align-v="center">
                <b-form-checkbox switch size="lg" v-model="data.section_1.enable"></b-form-checkbox>
                <div style="margin:auto;"></div>
                <b-button variant="primary" class="mt-2 float-right" @click="saveEnable">Save</b-button>
            </b-row>
        </b-card>
        <b-card title="Slider">
            <b-row class="p-0 m-0">
                <b-col cols="4" class="p-0 m-0" v-for="(img, index) in data.section_1.slider" :key="index">
                    <b-img class="w-100 p-0 m-0" :src="$baseUrl + img" :alt="$baseUrl + img" role="button" style="aspect-ratio: 1;"></b-img>
                    <div
                        style="position: absolute;top:0;height: 100%;width: 100%;left: 0;background: #22222288;pointer-events:all;display: block;padding: 8px;">
                        <b-button class="float-right ml-1" variant="info"
                            @click="showImageModal({ section: 'section_1', index: index, oldImg: img })"><b-icon icon="pencil-fill"
                                aria-label="Edit"></b-icon></b-button>
                        <b-button class="float-right" variant="danger" @click="deleteImg(index)"><b-icon
                                icon="trash-fill" aria-label="Delete"></b-icon></b-button>
                    </div>
                </b-col>
                <b-col cols="4" class="p-0 m-0">
                    <b-button class="w-100 h-100" variant="info" style="aspect-ratio: 1;"
                    @click="showImageModal({ section: 'section_1' })">
                        <b-icon icon="plus-lg" aria-label="Add"></b-icon>
                    </b-button>
                </b-col>
            </b-row>
        </b-card>
        <b-card title="Card" class="mt-2">
            <b-form v-for="(val, key) in data.section_1.card" :key="key" @submit.prevent="save('section_1', 'card', key)">
                <b-form-group :label="key">
                    <b-input-group prepend="EN">
                        <b-form-input v-model="data.section_1.card[key].en" />
                    </b-input-group>
                    <b-input-group prepend="FR" class="mt-1">
                        <b-form-input v-model="data.section_1.card[key].fr" />
                    </b-input-group>
                    <b-button variant="primary" type="submit" class="mt-2 float-right">Save</b-button>
                </b-form-group>
            </b-form>
        </b-card>
    </div>
</template>
<script>
import { getCookie } from '../../Api'
import axios from "axios";
export default {
    name: 'Section_1',
    props: ['data','showImageModal','save'],
    methods:{
        async saveEnable(){
            const p = this.$parent.$parent.$parent
            let payload = {
                    section: 'section_1',
                    enable:this.data.section_1.enable
                }
                p.isLoading = true
                try {
                    const res = await axios.post(
                        this.$baseUrl + "/api/section_enable",
                        payload,
                        {
                            headers: {
                                secret: getCookie("secret")
                            }
                        }
                    );
                    this.$bvToast.toast("updated", {
                        title: "Success",
                        variant: "success",
                        solid: true
                    });
                    p.onDataChanged(res.data)
                } catch (error) {
                    p.showError(error);
                }
                p.isLoading = false
        },
        async deleteImg(index){
            const p = this.$parent.$parent.$parent
            if (await this.$bvModal.msgBoxConfirm('Please confirm that you want to delete this item.', {
                title: 'Please Confirm',
                size: 'lg',
                buttonSize: 'md',
                okVariant: 'danger',
                okTitle: 'DELETE',
                cancelTitle: 'CANCEL',
                footerClass: 'p-2',
                hideHeaderClose: false,
                centered: true
            })) {
                let payload = {
                    field: 'delete_img',
                    index: index
                }
                p.isLoading = true
                try {
                    const res = await axios.post(
                        this.$baseUrl + "/api/section_1",
                        payload,
                        {
                            headers: {
                                secret: getCookie("secret")
                            }
                        }
                    );
                    this.$bvToast.toast("updated", {
                        title: "Success",
                        variant: "success",
                        solid: true
                    });
                    p.onDataChanged(res.data)
                } catch (error) {
                    p.showError(error);
                }
                p.isLoading = false
            }
        }
    }
}
</script>

<style></style>