<template>
    <div>
        <b-card title="Enable" class="mb-2">
            <b-row class="m-0 p-0" align-v="center">
                <b-form-checkbox switch size="lg" v-model="data.section_5.enable"></b-form-checkbox>
                <div style="margin:auto;"></div>
                <b-button variant="primary" class="mt-2 float-right" @click="saveEnable">Save</b-button>
            </b-row>
        </b-card>
        <b-card title="Images">
            <b-row class="p-0 m-0">
                <b-col sm="12" md="4" class="p-0 m-0" v-for="(img, index) in data.section_5.images" :key="index">
                    <b-img class="w-100 p-0 m-0" :src="$baseUrl + img" :alt="$baseUrl + img"
                        style="aspect-ratio: 1/1;"></b-img>
                    <div
                        style="position: absolute;top:0;height: 100%;width: 100%;left: 0;background: #22222288;pointer-events:all;display: block;padding: 8px;">
                        <b-button class="float-right ml-1" variant="info"
                            @click="showImageModal({ section: 'section_5', index: index, oldImg: img })"><b-icon
                                icon="pencil-fill" aria-label="Edit"></b-icon></b-button>
                        <b-button class="float-right" variant="danger" @click="deleteSection5Item(index)"><b-icon
                                icon="trash-fill" aria-label="Delete"></b-icon></b-button>
                    </div>
                </b-col>
                <b-col sm="12" md="4" class="p-0 m-0" style="aspect-ratio: 1/1;">
                    <b-button class="w-100 h-100" variant="info" @click="showImageModal({ section: 'section_5' })"><b-icon
                            icon="plus-lg" aria-label="Add"></b-icon></b-button>
                </b-col>
            </b-row>
        </b-card>
    </div>
</template>
<script>
import { getCookie } from '../../Api'
import axios from "axios";
export default {
    name: 'Section_5',
    props: ['data','showImageModal','deleteSection5Item'],
    methods:{
        async saveEnable(){
            const p = this.$parent.$parent.$parent
            let payload = {
                    section: 'section_5',
                    enable:this.data.section_5.enable
                }
                p.isLoading = true
                try {
                    const res = await axios.post(
                        this.$baseUrl + "/api/section_enable",
                        payload,
                        {
                            headers: {
                                secret: getCookie("secret")
                            }
                        }
                    );
                    this.$bvToast.toast("updated", {
                        title: "Success",
                        variant: "success",
                        solid: true
                    });
                    p.onDataChanged(res.data)
                } catch (error) {
                    p.showError(error);
                }
                p.isLoading = false
        }
    }
}
</script>

<style></style>