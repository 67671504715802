<template>
    <div>
        <b-card title="Enable" class="mb-2">
            <b-row class="m-0 p-0" align-v="center">
                <b-form-checkbox switch size="lg" v-model="data.section_4.enable"></b-form-checkbox>
                <div style="margin:auto;"></div>
                <b-button variant="primary" class="mt-2 float-right" @click="saveEnable">Save</b-button>
            </b-row>
        </b-card>
        <b-card title="Top" class="mt-2">
            <b-form @submit.prevent="saveSection4('title')">
                <b-form-group label="Title">
                    <b-input-group prepend="EN">
                        <b-form-input v-model="data.section_4.title.en" />
                    </b-input-group>
                    <b-input-group prepend="FR" class="mt-1">
                        <b-form-input v-model="data.section_4.title.fr" />
                    </b-input-group>
                    <b-button variant="primary" type="submit" class="mt-2 float-right">Save</b-button>
                </b-form-group>
            </b-form>
            <b-form @submit.prevent="saveSection4('subtitle')">
                <b-form-group label="Subtitle">
                    <b-input-group prepend="EN">
                        <b-form-input v-model="data.section_4.subtitle.en" />
                    </b-input-group>
                    <b-input-group prepend="FR" class="mt-1">
                        <b-form-input v-model="data.section_4.subtitle.fr" />
                    </b-input-group>
                    <b-button variant="primary" type="submit" class="mt-2 float-right">Save</b-button>
                </b-form-group>
            </b-form>
        </b-card>
        <b-card title="Items" class="mt-2">
            <div class="s4 text-center mt-3">
                <b-row class="m-0 p-0">
                    <b-col cols="12" v-for="(item, index) in data.section_4.items" :key="'price_' + index"
                        class="m-0 p-0 mb-3">
                        <div
                            style="border-style: solid;border-color: #000000;border-left-width: 1px;border-right-width: 1px;border-bottom-width: 1px;transition-duration: 0.4s;">
                            <div
                                style="background: #000000;padding-top: 20px;padding-bottom: 20px;padding-left: 20px;padding-right: 20px;">
                                <div style="padding-top: 10px;padding-bottom: 10px;background-color: #242424;border-top-left-radius: 30px;border-top-right-radius: 30px;border-bottom-left-radius: 30px;border-bottom-right-radius: 30px;transition-duration: 0.4s;color:#ffffff;font-weight: 400;font-style: normal;font-family: 'Titillium Web', sans-serif;color: #ffffff;font-size: 18px;line-height: 1.2;letter-spacing: 0px;"
                                    v-html="item.title.fr"></div>
                            </div>
                            <p style="font-weight: 700;font-style: normal;font-family: 'Titillium Web', sans-serif;color: #000000;font-size: 30px;line-height: 0.9;letter-spacing: 0px;padding-left: 16px;padding-right: 16px;padding-top: 40px;padding-bottom: 40px;background-color: #f9f9f9;"
                                v-html="item.price.fr"></p>
                            <p style="color: #000000;font-weight: 400;font-style: normal;font-family: 'Titillium Web', sans-serif;font-size: 18px;line-height: 1.2;letter-spacing: 0px;"
                                v-html="item.name.fr"></p>
                            <div style="background: #000000;width: 64px;height: 1px;" class="my-2 mx-auto"></div>
                            <p style="color: #000000;font-weight: 400;font-style: normal;font-family: 'Titillium Web', sans-serif;font-size: 14px;line-height: 1.2;letter-spacing: 0px;"
                                v-html="item.desc.fr"></p>
                            <b-button pill class="ii-button ii-button-2 mb-4">{{ item.button.fr }}</b-button>
                        </div>
                        <div
                            style="position: absolute;top:0;height: 100%;width: 100%;left: 0;background: #22222288;pointer-events:all;display: block;padding: 8px;">
                            <b-button class="float-right ml-1" variant="info"
                                @click="showEditSection4ItemModal(item, index)"><b-icon icon="pencil-fill"
                                    aria-label="Edit"></b-icon></b-button>
                            <b-button class="float-right" variant="danger" @click="deleteSection4Item(index)"><b-icon
                                    icon="trash-fill" aria-label="Delete"></b-icon></b-button>
                        </div>
                    </b-col>
                    <b-col sm="12" md="" class="p-0 m-0">
                        <b-button class="w-100 h-100" variant="info" @click="showAddSection4ItemModal"><b-icon
                                icon="plus-lg" aria-label="Add"></b-icon></b-button>
                    </b-col>
                </b-row>
            </div>
        </b-card>
    </div>
</template>

<script>
import { getCookie } from '../../Api'
import axios from "axios";
export default {
    name: 'Section_4',
    props: ['data','showAddSection4ItemModal','deleteSection4Item','showEditSection4ItemModal','saveSection4'],
    methods:{
        async saveEnable(){
            const p = this.$parent.$parent.$parent
            let payload = {
                    section: 'section_4',
                    enable:this.data.section_4.enable
                }
                p.isLoading = true
                try {
                    const res = await axios.post(
                        this.$baseUrl + "/api/section_enable",
                        payload,
                        {
                            headers: {
                                secret: getCookie("secret")
                            }
                        }
                    );
                    this.$bvToast.toast("updated", {
                        title: "Success",
                        variant: "success",
                        solid: true
                    });
                    p.onDataChanged(res.data)
                } catch (error) {
                    p.showError(error);
                }
                p.isLoading = false
        }
    }
}
</script>

<style></style>