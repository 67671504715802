<template>
    <div>
        <b-card title="Enable" class="mb-2">
            <b-row class="m-0 p-0" align-v="center">
                <b-form-checkbox switch size="lg" v-model="data.section_3.enable"></b-form-checkbox>
                <div style="margin:auto;"></div>
                <b-button variant="primary" class="mt-2 float-right" @click="saveEnable">Save</b-button>
            </b-row>
        </b-card>
        <b-card title="Items" class="mt-2">
            <b-row class="w-100 p-0 m-0">
                <b-col sm="12" md="6" class="p-0 m-0" v-for="(item, index) in data.section_3.items" :key="'s3_' + index">
                    <div
                        :style="{ padding: '16px', backgroundImage: 'url(' + $baseUrl + item.img + ')', height: '400px', backgroundPosition: 'center top', backgroundSize: 'cover', backgroundRepeat: 'no-repeat' }">
                        <b-button class="float-right ml-1" variant="info"
                            @click="showEditSection3ItemModal(item, index)"><b-icon icon="pencil-fill"
                                aria-label="Edit"></b-icon></b-button>
                        <b-button class="float-right" variant="danger" @click="deleteSection3Item(index)"><b-icon
                                icon="trash-fill" aria-label="Delete"></b-icon></b-button>
                        <h3 style="text-shadow: 1px 1px 2px black;margin-top: 80px;font-weight: 400;font-style: normal;font-family: 'Titillium Web', 'sans-serif';color: #ffffff;font-size: 17px;line-height: 1.2;letter-spacing: 0px;"
                            v-html="item.title.fr"></h3>
                        <p style="font-weight: 400;text-shadow: 1px 1px 2px black;padding-top: 16px;font-style: normal;font-family: 'Titillium Web', sans-serif;color: #ffffff;font-size: 15px;line-height: 1.2;letter-spacing: 0px;"
                            v-html="item.body.fr"></p>
                    </div>
                </b-col>
                <b-col sm="12" md="" class="p-0 m-0">
                    <b-button class="w-100 h-100" variant="info" @click="showAddSection3ItemModal"><b-icon icon="plus-lg"
                            aria-label="Add"></b-icon></b-button>
                </b-col>
            </b-row>
        </b-card>
    </div>
</template>

<script>
import { getCookie } from '../../Api'
import axios from "axios";
export default {
    name: 'Section_3',
    props: ['data','showEditSection3ItemModal','deleteSection3Item','showAddSection3ItemModal'],
    methods:{
        async saveEnable(){
            const p = this.$parent.$parent.$parent
            let payload = {
                    section: 'section_3',
                    enable:this.data.section_3.enable
                }
                p.isLoading = true
                try {
                    const res = await axios.post(
                        this.$baseUrl + "/api/section_enable",
                        payload,
                        {
                            headers: {
                                secret: getCookie("secret")
                            }
                        }
                    );
                    this.$bvToast.toast("updated", {
                        title: "Success",
                        variant: "success",
                        solid: true
                    });
                    p.onDataChanged(res.data)
                } catch (error) {
                    p.showError(error);
                }
                p.isLoading = false
        }
    }
}
</script>

<style></style>