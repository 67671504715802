<template>
    <div>
        <b-card title="Enable" class="mb-2">
            <b-row class="m-0 p-0">
                <b-form-checkbox switch size="lg" v-model="data.footer.enable"></b-form-checkbox>
                <div style="margin:auto"></div>
                <b-button variant="primary" class="mt-2 float-right" @click="saveEnable">Save</b-button>
            </b-row>
        </b-card>
        <b-card title="Top">
            <b-form v-for="(val, key) in data.footer.top" :key="key" @submit.prevent="save('footer', 'top', key)">
                <b-form-group :label="key">
                    <b-input-group prepend="EN">
                        <b-form-input v-model="data.footer.top[key].en" />
                    </b-input-group>
                    <b-input-group prepend="FR" class="mt-1">
                        <b-form-input v-model="data.footer.top[key].fr" />
                    </b-input-group>
                    <b-button variant="primary" type="submit" class="mt-2 float-right">Save</b-button>
                </b-form-group>
            </b-form>
        </b-card>
        <b-card title="Items" class="mt-2">
            <div v-for="(item, index) in data.footer.items" class="p-0 m-0 mt-4" :key="index">
                <b-row class="m-0 p-0">
                    <div style="background: var(--primary); width: 50px;height: 50px;border-radius: 4px;padding: 12px;">
                        <b-icon :icon="item.icon" class="w-100 h-100"></b-icon>
                    </div>
                    <b-col class="py-0">
                        <h3 class="p-0 m-0 pt-1"
                            style="font-weight: 400;font-style: normal;font-family: 'Titillium Web, sans-serif';color: #010101;font-size: 20px;line-height: 0.8;letter-spacing: 0px;">
                            {{ item.title.fr }}</h3>
                        <p class="p-0 m-0 pt-1"
                            style="font-weight: 400;font-style: normal;font-family: 'Open Sans', sans-serif;color: #828282;font-size: 16px;line-height: 1.4;letter-spacing: 0px;">
                            {{ item.body.fr }}</p>
                    </b-col>
                    <div class="ml-auto my-auto">
                        <b-button class="float-right ml-1" variant="info"
                            @click="showEditFooterItemModal(item, index)"><b-icon icon="pencil-fill"
                                aria-label="Edit"></b-icon></b-button>
                        <b-button class="float-right" variant="danger" @click="deleteFooterItem(index)"><b-icon
                                icon="trash-fill" aria-label="Delete"></b-icon></b-button>
                    </div>
                </b-row>
            </div>
            <div class="p-0 m-0 mt-4">
                <b-button class="w-100" variant="primary" @click="showAddFooterItemModal"><b-icon icon="plus-lg"
                        aria-label="Add"></b-icon></b-button>
            </div>
        </b-card>
        <b-modal :title="modalData.modalTitle" id="footerItemsModal" hide-footer>
            <b-form @submit.prevent="modalSubmit">
                <b-icon :icon="modalData.icon" style="border: 1px solid black;height: 100px;width: 100px;padding: 12px;"
                    @click="showIconsModal('footer', modalData.index, modalData.icon)"></b-icon>
                <b-form-group label="Title">
                    <b-input-group prepend="EN">
                        <b-form-input v-model="modalData.title_en"></b-form-input>
                    </b-input-group>
                    <b-input-group prepend="FR" class="mt-1">
                        <b-form-input v-model="modalData.title_fr"></b-form-input>
                    </b-input-group>
                </b-form-group>
                <b-form-group label="Body">
                    <b-input-group prepend="EN">
                        <b-form-input v-model="modalData.body_en"></b-form-input>
                    </b-input-group>
                    <b-input-group prepend="FR" class="mt-1">
                        <b-form-input v-model="modalData.body_fr"></b-form-input>
                    </b-input-group>
                </b-form-group>
                <b-form-group label="href">
                    <b-form-input v-model="modalData.href"></b-form-input>
                </b-form-group>
                <b-button class="float-right" type="submit" variant="primary">Submit</b-button>
            </b-form>
        </b-modal>
    </div>
</template>  
<script>
import { getCookie } from '../../Api'
import axios from "axios";
export default {
    name: "Footer",
    props: ["data", 'save', 'showIconsModal'],
    data() {
        return {
            modalData: {}
        }
    },
    methods: {
        async saveEnable(){
            const p = this.$parent.$parent.$parent
            let payload = {
                    section: 'footer',
                    enable:this.data.footer.enable
                }
                p.isLoading = true
                try {
                    const res = await axios.post(
                        this.$baseUrl + "/api/section_enable",
                        payload,
                        {
                            headers: {
                                secret: getCookie("secret")
                            }
                        }
                    );
                    this.$bvToast.toast("updated", {
                        title: "Success",
                        variant: "success",
                        solid: true
                    });
                    p.onDataChanged(res.data)
                } catch (error) {
                    p.showError(error);
                }
                p.isLoading = false
        },
        async deleteFooterItem(index) {
            const p = this.$parent.$parent.$parent
            if (await this.$bvModal.msgBoxConfirm('Please confirm that you want to delete this item.', {
                title: 'Please Confirm',
                size: 'lg',
                buttonSize: 'md',
                okVariant: 'danger',
                okTitle: 'DELETE',
                cancelTitle: 'CANCEL',
                footerClass: 'p-2',
                hideHeaderClose: false,
                centered: true
            })) {
                let payload = {
                    field: 'delete_item',
                    index: index
                }
                p.isLoading = true
                try {
                    const res = await axios.post(
                        this.$baseUrl + "/api/footer",
                        payload,
                        {
                            headers: {
                                secret: getCookie("secret")
                            }
                        }
                    );
                    this.$bvToast.toast("updated", {
                        title: "Success",
                        variant: "success",
                        solid: true
                    });
                    p.onDataChanged(res.data)
                } catch (error) {
                    p.showError(error);
                }
                p.isLoading = false
            }
        },
        async modalSubmit() {
            const p = this.$parent.$parent.$parent
            let payload = {
                field: 'items',
                title_en: this.modalData.title_en,
                title_fr: this.modalData.title_fr,
                body_en: this.modalData.body_en,
                body_fr: this.modalData.body_en,
                icon: this.modalData.icon,
                href: this.modalData.href,
            }
            if (this.modalData.index != undefined) {
                payload.index = this.modalData.index
            }
            p.isLoading = true
            try {
                const res = await axios.post(
                    this.$baseUrl + "/api/footer",
                    payload,
                    {
                        headers: {
                            secret: getCookie("secret")
                        }
                    }
                );
                this.$bvModal.hide('footerItemsModal')
                this.$bvToast.toast("updated", {
                    title: "Success",
                    variant: "success",
                    solid: true
                });
                p.onDataChanged(res.data)
            } catch (error) {
                p.showError(error);
            }
            p.isLoading = false
        },
        showAddFooterItemModal() {
            this.modalData = { modalTitle: 'Add Item' }
            this.$bvModal.show('footerItemsModal')
        },
        showEditFooterItemModal(item, index) {
            this.modalData = {
                modalTitle: 'Edit Item',
                title_en: item.title.en,
                title_fr: item.title.fr,
                body_en: item.body.en,
                body_fr: item.body.fr,
                icon: item.icon,
                href: item.href,
                index: index
            }
            this.$bvModal.show('footerItemsModal')
        }
    }
};
</script>
  
<style></style>