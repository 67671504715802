<template>
  <div>
    <b-card title="Enable" class="mb-2">
            <b-row class="m-0 p-0" align-v="center">
                <b-form-checkbox switch size="lg" v-model="data.header.enable"></b-form-checkbox>
                <div style="margin:auto;"></div>
                <b-button variant="primary" class="mt-2 float-right" @click="saveEnable">Save</b-button>
            </b-row>
        </b-card>
    <b-form v-for="(val, key) in data.header.top" :key="key" @submit.prevent="save('header', 'top', key)">
      <b-form-group :label="key">
        <b-input-group prepend="EN">
          <b-form-input v-model="data.header.top[key].en" />
        </b-input-group>
        <b-input-group prepend="FR" class="mt-1">
          <b-form-input v-model="data.header.top[key].fr" />
        </b-input-group>
        <b-button variant="primary" type="submit" class="mt-2 float-right">Save</b-button>
      </b-form-group>
    </b-form>
  </div>
</template>

<script>
import axios from "axios";
import { getCookie } from "../../Api";
export default {
  name: "Header",
  props: ["data",'save'],
  methods:{
    async saveEnable(){
            const p = this.$parent.$parent.$parent
            let payload = {
                    section: 'header',
                    enable:this.data.header.enable
                }
                p.isLoading = true
                try {
                    const res = await axios.post(
                        this.$baseUrl + "/api/section_enable",
                        payload,
                        {
                            headers: {
                                secret: getCookie("secret")
                            }
                        }
                    );
                    this.$bvToast.toast("updated", {
                        title: "Success",
                        variant: "success",
                        solid: true
                    });
                    p.onDataChanged(res.data)
                } catch (error) {
                    p.showError(error);
                }
                p.isLoading = false
        },
  }
};
</script>

<style>
</style>