<template>
  <div>
    <b-navbar toggleable="lg" type="dark" variant="info">
      <b-navbar-brand :href="$baseUrl">INFINITE INSPECT</b-navbar-brand>
      <b-navbar-nav v-if="data" class="ml-auto">
        <b-button v-b-modal.addImageModal>Add Image</b-button>
      </b-navbar-nav>
    </b-navbar>
    <b-card v-if="!data" title="Login" class="m-1">
      <b-form @submit.prevent="login">
        <b-form-group label="Username" class="mt-2">
          <b-form-input v-model="username"></b-form-input>
        </b-form-group>
        <b-form-group label="Password" class="mt-2">
          <b-form-input type="password" v-model="password"></b-form-input>
        </b-form-group>
        <b-button type="submit" class="float-end mt-3" variant="primary">Login</b-button>
      </b-form>
    </b-card>
    <b-card v-else no-body class="m-1">
      <b-tabs card content-class="mt-3">
        <b-tab title="Themes">
          <Themes :data="data"></Themes>
        </b-tab>
        <b-tab title="Header">
          <Header :data="data" :save="save"></Header>
        </b-tab>
        <b-tab title="Section 1">
          <Section_1 :data="data" :save="save" :showImageModal="showImageModal"></Section_1>
        </b-tab>
        <b-tab title="Section 2">
          <Section_2 :data="data"></Section_2>
        </b-tab>
        <b-tab title="Section 3">
          <Section_3 :showEditSection3ItemModal="showEditSection3ItemModal"
            :showAddSection3ItemModal="showAddSection3ItemModal" :deleteSection3Item="deleteSection3Item" :data="data">
          </Section_3>
        </b-tab>
        <b-tab title="Section 4">
          <Section_4 :data="data" :showAddSection4ItemModal="showAddSection4ItemModal"
            :deleteSection4Item="deleteSection4Item" :showEditSection4ItemModal="showEditSection4ItemModal"
            :saveSection4="saveSection4"></Section_4>
        </b-tab>
        <b-tab title="Section 5">
          <Section_5 :data="data" :showImageModal="showImageModal" :deleteSection5Item="deleteSection5Item"></Section_5>
        </b-tab>
        <b-tab title="Section 6">
          <Section_6 :data="data"></Section_6>
        </b-tab>
        <b-tab title="Section 7">
          <Section_7 :data="data"></Section_7>
        </b-tab>
        <b-tab title="Footer">
          <Footer ref="footer" :data="data" :save="save" :showIconsModal="showIconsModal"></Footer>
        </b-tab>
      </b-tabs>
    </b-card>
    <b-modal id="imageModal" title="Images" size="lg" hide-footer>
      <div v-if="images && imageModalData" class="w-100">
        <b-row class="w-100 m-0 p-0">
          <b-col v-for="(img, index) in images" :key="index" cols="4" class="p-0 m-0" style="aspect-ratio: 1;">
            <b-img :src="$baseUrl + img" fluid :style="{ border: img == imageModalData.oldImg ? '4px solid red' : '' }"
              role="button" @click="saveImage(img)"></b-img>
          </b-col>
        </b-row>
      </div>
    </b-modal>
    <b-modal title="Add Image" id="addImageModal" hide-footer @hidden="fileToUpload = null">
      <b-form @submit.prevent="uploadImage">
        <b-form-file v-model="fileToUpload" placeholder="Choose a image or drop it here..."
          drop-placeholder="Drop image here..."></b-form-file>
        <b-button type="submit" class="float-right mt-1" variant="primary">Submit</b-button>
      </b-form>
    </b-modal>
    <b-modal id="iconsModal" title="Icons" hide-footer>
      <b-row v-if="iconModalData" class="w-100 m-0 p-0">
        <b-col v-for="(icon, index) in iconList" :key="index" cols="2" class="p-0 m-0">
          <b-card role="button" class="text-center m-1 p-2" no-body
            :style="{ border: icon == iconModalData.oldIcon ? '4px solid red' : '' }" @click="setIcon(icon)">
            <b-icon :icon="icon" class="m-auto"></b-icon>
          </b-card>
        </b-col>
      </b-row>
    </b-modal>
    <b-modal id="section3ItemModal" :title="section3ItemModalData.modalTitle" hide-footer>
      <b-form @submit.prevent="saveSection3Item">
        <b-img :src="section3ItemModalData.img ? $baseUrl + section3ItemModalData.img : null"
          style="width: 100px;height:100px;margin: 8px;" role="button"
          @click="showImageModal({ section: 'section_3' })"></b-img>
        <b-form-group label="Title">
          <b-input-group prepend="EN">
            <b-form-input v-model="section3ItemModalData.title_en" />
          </b-input-group>
          <b-input-group prepend="FR" class="mt-1">
            <b-form-input v-model="section3ItemModalData.title_fr" />
          </b-input-group>
        </b-form-group>
        <b-form-group label="Body">
          <b-input-group prepend="EN">
            <b-form-input v-model="section3ItemModalData.body_en" />
          </b-input-group>
          <b-input-group prepend="FR" class="mt-1">
            <b-form-input v-model="section3ItemModalData.body_fr" />
          </b-input-group>
        </b-form-group>
        <b-button variant="primary" type="submit" class="my-2 float-right">Save</b-button>
      </b-form>
    </b-modal>
    
    <b-modal id="section4ItemModal" :title="section4ItemModalData.modalTitle" hide-footer>
      <b-form @submit.prevent="saveSection4Item">
        <b-form-group label="Title">
          <b-input-group prepend="EN">
            <b-form-input v-model="section4ItemModalData.title_en" />
          </b-input-group>
          <b-input-group prepend="FR" class="mt-1">
            <b-form-input v-model="section4ItemModalData.title_fr" />
          </b-input-group>
        </b-form-group>
        <b-form-group label="Price">
          <b-input-group prepend="EN">
            <b-form-input v-model="section4ItemModalData.price_en" />
          </b-input-group>
          <b-input-group prepend="FR" class="mt-1">
            <b-form-input v-model="section4ItemModalData.price_fr" />
          </b-input-group>
        </b-form-group>
        <b-form-group label="Name">
          <b-input-group prepend="EN">
            <b-form-input v-model="section4ItemModalData.name_en" />
          </b-input-group>
          <b-input-group prepend="FR" class="mt-1">
            <b-form-input v-model="section4ItemModalData.name_fr" />
          </b-input-group>
        </b-form-group>
        <b-form-group label="Desc">
          <b-input-group prepend="EN">
            <b-form-input v-model="section4ItemModalData.desc_en" />
          </b-input-group>
          <b-input-group prepend="FR" class="mt-1">
            <b-form-input v-model="section4ItemModalData.desc_fr" />
          </b-input-group>
        </b-form-group>
        <b-form-group label="Button">
          <b-input-group prepend="EN">
            <b-form-input v-model="section4ItemModalData.button_en" />
          </b-input-group>
          <b-input-group prepend="FR" class="mt-1">
            <b-form-input v-model="section4ItemModalData.button_fr" />
          </b-input-group>
        </b-form-group>
        <b-button variant="primary" type="submit" class="my-2 float-right">Save</b-button>
      </b-form>
    </b-modal>
    <b-overlay :show="isLoading" no-wrap fixed style="z-index:2000"></b-overlay>
  </div>
</template>
<script>
import Section_1 from "./Section_1";
import Section_2 from "./Section_2";
import Section_3 from "./Section_3";
import Section_4 from "./Section_4";
import Section_5 from "./Section_5";
import Section_6 from "./Section_6";
import Section_7 from "./Section_7";
import Header from "./Header";
import Footer from "./Footer";
import Themes from "./Themes";
import {setCookie,getCookie,eraseCookie} from '../../Api'
import axios from "axios";
import iconList from "../../assets/iconList";
export default {
  name: "Dashboard",
  components: { Themes,Header, Section_1, Section_2, Section_3, Section_4, Section_5,Section_6,Section_7, Footer },
  data() {
    return {
      password: "",
      username: "",
      data: null,
      isLoading: false,
      images: null,
      imageModalData: null,
      iconModalData: null,
      fileToUpload: null,
      iconList,
      
      section3ItemModalData: {},
      section4ItemModalData: {}
    };
  },
  methods: {
    onDataChanged(data){
      this.data = data
    },
    async deleteSection5Item(index) {
      if (await this.$bvModal.msgBoxConfirm('Please confirm that you want to delete this item.', {
        title: 'Please Confirm',
        size: 'lg',
        buttonSize: 'md',
        okVariant: 'danger',
        okTitle: 'DELETE',
        cancelTitle: 'CANCEL',
        footerClass: 'p-2',
        hideHeaderClose: false,
        centered: true
      })) {
        let payload = {
          field: 'delete_item',
          index: index
        }
        this.isLoading = true
        try {
          const res = await axios.post(
            this.$baseUrl + "/api/section_5",
            payload,
            {
              headers: {
                secret: getCookie("secret")
              }
            }
          );
          this.$bvToast.toast("updated", {
            title: "Success",
            variant: "success",
            solid: true
          });
          this.data = res.data
        } catch (error) {
          this.showError(error);
        }
        this.isLoading = false
      }
    },
    async deleteSection4Item(index) {
      if (await this.$bvModal.msgBoxConfirm('Please confirm that you want to delete this item.', {
        title: 'Please Confirm',
        size: 'lg',
        buttonSize: 'md',
        okVariant: 'danger',
        okTitle: 'DELETE',
        cancelTitle: 'CANCEL',
        footerClass: 'p-2',
        hideHeaderClose: false,
        centered: true
      })) {
        let payload = {
          field: 'delete_item',
          index: index
        }
        this.isLoading = true
        try {
          const res = await axios.post(
            this.$baseUrl + "/api/section_4",
            payload,
            {
              headers: {
                secret: getCookie("secret")
              }
            }
          );
          this.$bvToast.toast("updated", {
            title: "Success",
            variant: "success",
            solid: true
          });
          this.data = res.data
        } catch (error) {
          this.showError(error)
        }
        this.isLoading = false
      }
    },
   
    showAddSection4ItemModal() {
      this.section4ItemModalData = {
        modalTitle: 'Add Item'
      }
      this.$bvModal.show('section4ItemModal')
    },
    showEditSection4ItemModal(item, index) {
      this.section4ItemModalData = {
        modalTitle: 'Edit Item',
        title_en: item.title.en,
        title_fr: item.title.fr,
        desc_en: item.desc.en,
        desc_fr: item.desc.fr,
        price_en: item.price.en,
        price_fr: item.price.fr,
        name_en: item.name.en,
        name_fr: item.name.fr,
        button_en: item.button.en,
        button_fr: item.button.fr,
        index: index
      }
      this.$bvModal.show('section4ItemModal')
    },
    async deleteSection3Item(index) {
      if (await this.$bvModal.msgBoxConfirm('Please confirm that you want to delete this item.', {
        title: 'Please Confirm',
        size: 'lg',
        buttonSize: 'md',
        okVariant: 'danger',
        okTitle: 'DELETE',
        cancelTitle: 'CANCEL',
        footerClass: 'p-2',
        hideHeaderClose: false,
        centered: true
      })) {
        let payload = {
          field: 'delete_item',
          index: index
        }
        this.isLoading = true
        try {
          const res = await axios.post(
            this.$baseUrl + "/api/section_3",
            payload,
            {
              headers: {
                secret: getCookie("secret")
              }
            }
          );
          this.$bvToast.toast("updated", {
            title: "Success",
            variant: "success",
            solid: true
          });
          this.data = res.data
        } catch (error) {
          this.showError(error)
        }
        this.isLoading = false
      }
    },
    showEditSection3ItemModal(item, index) {
      this.section3ItemModalData = {
        modalTitle: 'Edit Item',
        title_en: item.title.en,
        title_fr: item.title.fr,
        body_en: item.body.en,
        body_fr: item.body.fr,
        img: item.img,
        index: index
      }
      this.$bvModal.show('section3ItemModal')
    },
    showAddSection3ItemModal() {
      this.section3ItemModalData = { modalTitle: 'Add Item' }
      this.$bvModal.show('section3ItemModal')
    },
    async saveSection4Item() {
      let payload = {
        field: 'items',
        title_en: this.section4ItemModalData.title_en,
        title_fr: this.section4ItemModalData.title_fr,
        price_en: this.section4ItemModalData.price_en,
        price_fr: this.section4ItemModalData.price_fr,
        desc_en: this.section4ItemModalData.desc_en,
        desc_fr: this.section4ItemModalData.desc_fr,
        name_en: this.section4ItemModalData.name_en,
        name_fr: this.section4ItemModalData.name_fr,
        button_en: this.section4ItemModalData.button_en,
        button_fr: this.section4ItemModalData.button_fr,
      }
      this.isLoading = true
      try {
        if (this.section4ItemModalData.index) {
          payload.index = this.section4ItemModalData.index
        }
        const res = await axios.post(
          this.$baseUrl + "/api/section_4",
          payload,
          {
            headers: {
              secret: getCookie("secret")
            }
          }
        );
        this.$bvModal.hide('section4ItemModal')
        this.$bvToast.toast("updated", {
          title: "Success",
          variant: "success",
          solid: true
        });
        this.data = res.data
      } catch (error) {
        this.showError(error);
      }
      this.isLoading = false
    },
    async saveSection3Item() {
      let payload = {
        field: 'items',
        img: this.section3ItemModalData.img,
        title_en: this.section3ItemModalData.title_en,
        title_fr: this.section3ItemModalData.title_fr,
        body_en: this.section3ItemModalData.body_en,
        body_fr: this.section3ItemModalData.body_fr,
      }
      this.isLoading = true
      try {
        if (this.section3ItemModalData.index != undefined) {
          payload.index = this.section3ItemModalData.index
        }
        const res = await axios.post(
          this.$baseUrl + "/api/section_3",
          payload,
          {
            headers: {
              secret: getCookie("secret")
            }
          }
        );
        this.$bvModal.hide('section3ItemModal')
        this.$bvToast.toast("updated", {
          title: "Success",
          variant: "success",
          solid: true
        });
        this.data = res.data
      } catch (error) {
        this.showError(error);
      }
      this.isLoading = false
    },
    async saveSection2Item(index) {
      const payload = {
        field: 'items',
        icon: this.data.section_2.items[index].icon,
        title_en: this.data.section_2.items[index].title.en,
        title_fr: this.data.section_2.items[index].title.fr,
        body_en: this.data.section_2.items[index].body.en,
        body_fr: this.data.section_2.items[index].body.fr,
        index: index
      }
      this.isLoading = true
      try {
        const res = await axios.post(
          this.$baseUrl + "/api/section_2",
          payload,
          {
            headers: {
              secret: getCookie("secret")
            }
          }
        );
        this.$bvToast.toast("updated", {
          title: "Success",
          variant: "success",
          solid: true
        });
        this.data = res.data
      } catch (error) {
        this.showError(error);
      }
      this.isLoading = false
    },
    async saveSection4(field, index) {
      let payload = {};
      if (field == 'title') {
        payload = {
          field: 'title',
          en: this.data.section_4.title.en,
          fr: this.data.section_4.title.fr
        }
      }
      if (field == 'subtitle') {
        payload = {
          field: 'subtitle',
          en: this.data.section_4.subtitle.en,
          fr: this.data.section_4.subtitle.fr
        }
      }
      if (field == 'items') {
        payload = {
          field: 'items',
          title_en: this.data.section_4.items[index].title.en,
          title_fr: this.data.section_4.items[index].title.fr,
          price_en: this.data.section_4.items[index].price.en,
          price_fr: this.data.section_4.items[index].price.fr,
          name_en: this.data.section_4.items[index].name.en,
          name_fr: this.data.section_4.items[index].name.fr,
          desc_en: this.data.section_4.items[index].desc.en,
          desc_fr: this.data.section_4.items[index].desc.fr,
          button_en: this.data.section_4.items[index].button.en,
          button_fr: this.data.section_4.items[index].button.fr,
          index: index
        }
      }
      this.isLoading = true
      try {
        const res = await axios.post(
          this.$baseUrl + "/api/section_4",
          payload,
          {
            headers: {
              secret: getCookie("secret")
            }
          }
        );
        this.$bvToast.toast("updated", {
          title: "Success",
          variant: "success",
          solid: true
        });
        this.data = res.data
      } catch (error) {
        this.showError(error);
      }
      this.isLoading = false
    },
    setIcon(icon) {
      if (this.iconModalData.section == 'section_2') {
        this.data.section_2.items[this.iconModalData.index].icon = icon
        this.$bvModal.hide('iconsModal')
      }
      if (this.iconModalData.section == 'footer') {
        this.$set(this.$refs.footer.modalData,'icon',icon)
        this.$bvModal.hide('iconsModal')
      }
    },
    async getImages() {
      this.isLoading = true
      try {
        const res = await axios.get(this.$baseUrl + '/api/images', {
          cache: false,
          headers: {
            secret: getCookie("secret")
          }
        })
        this.images = res.data
      } catch (error) {
        this.showError(error);
      }
      this.isLoading = false
    },
    showIconsModal(section, index, oldIcon) {
      this.iconModalData = {
        section: section, index: index, oldIcon: oldIcon
      }
      this.$bvModal.show('iconsModal')
    },
    async showImageModal(data) {
      if (!this.images) {
        await this.getImages()
      }
      if (data.section == 'section_1') {
        this.imageModalData = {
          section: 'section_1',
          field: 'slider',
          oldImg: data.oldImg,
          index: data.index
        }
      }
      if (data.section == 'section_2') {
        this.imageModalData = {
          section: 'section_2',
          oldImg: data.oldImg
        }
      }
      if (data.section == 'section_3') {
        this.imageModalData = {
          section: 'section_3',
          oldImg: this.section3ItemModalData.img,
          index: this.section3ItemModalData.index
        }
      }
      if (data.section == 'section_5') {
        this.imageModalData = {
          section: 'section_5',
          oldImg: data.oldImg,
          index: data.index
        }
      }
      if(data.section == 'section_2_items'){
        this.imageModalData = {
          section: 'section_2_item',
          oldImg: data.oldImg,
          index: data.index
        }
      }
      this.$bvModal.show('imageModal')
    },
    async saveImage(img) {
      if (this.imageModalData.section == 'section_1') {
        let payload = { img: img, field: this.imageModalData.field }
        if(this.imageModalData.index !== undefined){
            payload.index = this.imageModalData.index
        }
        this.isLoading = true
        try {
          const res = await axios.post(
            this.$baseUrl + "/api/section_1",
            payload,
            {
              headers: {
                secret: getCookie("secret")
              }
            }
          );
          this.$bvToast.toast("updated", {
            title: "Success",
            variant: "success",
            solid: true
          });
          this.data = res.data
          this.$bvModal.hide('imageModal')
        } catch (error) {
          this.showError(error);
        }
        this.isLoading = false
      }
      if (this.imageModalData.section == 'section_3') {
        this.$set(this.section3ItemModalData, 'img', img)
        this.$bvModal.hide('imageModal')
      }
      if (this.imageModalData.section == 'section_5') {
        let payload = { img: img }
        if (this.imageModalData.index !== undefined) {
          payload.index = this.imageModalData.index
        }
        this.isLoading = true
        try {
          const res = await axios.post(
            this.$baseUrl + "/api/section_5",
            payload,
            {
              headers: {
                secret: getCookie("secret")
              }
            }
          );
          this.$bvToast.toast("updated", {
            title: "Success",
            variant: "success",
            solid: true
          });
          this.data = res.data
          this.$bvModal.hide('imageModal')
        } catch (error) {
          this.showError(error);
        }
        this.isLoading = false
      }
    },
    async uploadImage() {
      if (this.fileToUpload) {
        this.isLoading = true
        const formData = new FormData()
        formData.append("image", this.fileToUpload)
        try {
          await axios.post(this.$baseUrl + '/api/images',
            formData
            , {
              headers: {
                'secret': getCookie('secret'),
              }
            }
          )
          this.$bvToast.toast('uploaded', {
            title: 'Success',
            variant: 'success',
            solid: true
          })
          this.getImages()
        }
        catch (error) {
          this.showError(error)
        }
        this.isLoading = false
        this.$bvModal.hide('addImageModal')
      }

    },
    async login() {
      this.isLoading = true
      try {
        const res = await axios.post(this.$baseUrl + "/api/login", {
          username: this.username,
          password: this.password
        });
        setCookie("secret", res.data.secret, res.data.secret_time_ms);
        this.password = "";
        this.username = "";
        await this.getData();
      } catch (error) {
        this.showError(error);
      }
      this.isLoading = false
    },
    async getData() {
      if (getCookie("secret")) {
        try {
          const res = await axios.get(this.$baseUrl + "/api/admin/data", {
            cache: false,
            headers: {
              secret: getCookie("secret")
            }
          });
          this.data = res.data;
        } catch (error) {
          this.showError(error);
        }
      }
    },
    async save(section, key, key2) {
      this.isLoading = true
      try {
        await axios.post(
          this.$baseUrl + "/api/data",
          {
            section: section,
            key: key,
            key2: key2,
            en: this.data[section][key][key2].en,
            fr: this.data[section][key][key2].fr
          },
          {
            headers: {
              secret: getCookie("secret")
            }
          }
        );
        this.$bvToast.toast("updated", {
          title: "Success",
          variant: "success",
          solid: true
        });
        this.getData();
      } catch (error) {
        this.showError(error);
      }
      this.isLoading = false
    },
    showError(error) {
      if (error && error.response && error.response.data) {
        if (
          error.response.data.error &&
          error.response.data.error == "unauthorized"
        ) {
          eraseCookie("secret");
          this.data = null;
        }
        this.$bvToast.toast(error.response.data.error, {
          title: "Error",
          variant: "danger",
          solid: true
        });
      } else {
        this.$bvToast.toast(error.message, {
          title: "Error",
          variant: "danger",
          solid: true
        });
      }
    }
  },
  mounted() {
    this.getData()
  }
};
</script>