<template>
    <div class="mx-2">
        <h2>Themes</h2>
        <b-card v-for="(item, index) in data.themes.items" :key="index" class="mb-2" 
            :style="{ border: index == data.themes.selectedIndex ? '3px solid red' : null }">
            <b-row align-v="center" align-h="between">
                <b-col cols="auto">
                    <b-row v-for="(val, key) in item" :key="key" class="m-0 p-0 mb-1" align-v="center">
                        <div :style="{ background: val, width: '50px', height: '50px', border: '2px solid black' }"></div>
                        <div class="ml-2">{{ key }}</div>
                    </b-row>
                </b-col>
                <b-button-group class="float-right" vertical>
                    <b-button variant="info" size="lg" 
                    @click="themeToEdit = { item: JSON.parse(JSON.stringify(item)), index: index }; $bvModal.show('editThemeModal')">Edit</b-button>
                    <b-button variant="success" size="lg" class="mt-1"
                    @click="setTheme(index)">Select</b-button>
                </b-button-group>
                
            </b-row>
        </b-card>
        <b-modal id="editThemeModal" title="Edit Theme" hide-footer>
            <b-form v-if="themeToEdit" @submit.prevent="editTheme">
                <b-input-group v-for="(val, key) in themeToEdit.item" :key="key" :prepend="key" class="mb-1">
                    <b-form-input type="color" v-model="themeToEdit.item[key]"></b-form-input>
                </b-input-group>
                <b-button variant="primary" class="float-right" type="submit">Save</b-button>
            </b-form>
        </b-modal>
    </div>
</template>

<script>
import { getCookie } from '../../Api'
import axios from "axios";
export default {
    name: 'Themes',
    props: ['data'],
    data() {
        return {
            themeToEdit: null
        }
    },
    methods: {
        async setTheme(index) {
            if (await this.$bvModal.msgBoxConfirm('Please confirm that you want to select this theme.', {
                title: 'Please Confirm',
                size: 'lg',
                buttonSize: 'md',
                okVariant: 'primary',
                okTitle: 'SELECT',
                cancelTitle: 'CANCEL',
                footerClass: 'p-2',
                hideHeaderClose: false,
                centered: true
            })) {
                const p = this.$parent.$parent.$parent
                let payload = {
                    field: 'chnage_selected_index',
                    selectedIndex: index,
                }
                p.isLoading = true
                try {
                    const res = await axios.post(
                        this.$baseUrl + "/api/themes",
                        payload,
                        {
                            headers: {
                                secret: getCookie("secret")
                            }
                        }
                    );
                    this.$bvModal.hide('editThemeModal')
                    this.$bvToast.toast("updated", {
                        title: "Success",
                        variant: "success",
                        solid: true
                    });
                    p.onDataChanged(res.data)
                } catch (error) {
                    p.showError(error);
                }
                p.isLoading = false
            }
        },
        async editTheme() {
            const p = this.$parent.$parent.$parent
            let payload = {
                field: 'items',
                primary: this.themeToEdit.item.primary,
                onPrimary: this.themeToEdit.item.onPrimary,
                background: this.themeToEdit.item.background,
                text: this.themeToEdit.item.text
            }
            if (this.themeToEdit.index != undefined) {
                payload.index = this.themeToEdit.index
            }
            p.isLoading = true
            try {
                const res = await axios.post(
                    this.$baseUrl + "/api/themes",
                    payload,
                    {
                        headers: {
                            secret: getCookie("secret")
                        }
                    }
                );
                this.$bvModal.hide('editThemeModal')
                this.$bvToast.toast("updated", {
                    title: "Success",
                    variant: "success",
                    solid: true
                });
                p.onDataChanged(res.data)
            } catch (error) {
                p.showError(error);
            }
            p.isLoading = false
        }
    }
}
</script>

