
<template>
    <div>
      <b-card title="Enable" class="mb-2">
        <b-row class="m-0 p-0" align-v="center">
          <b-form-checkbox switch size="lg" v-model="data.section_7.enable"></b-form-checkbox>
          <div style="margin:auto;"></div>
          <b-button variant="primary" class="mt-2 float-right" @click="saveEnable">Save</b-button>
        </b-row>
      </b-card>
      <b-card title="Items" class="mt-2">
        <b-row class="m-0 p-0" align-h="center">
          <b-card
            v-for="(item,index) in data.section_7.items"
            :key="index"
            :title="item.title.fr"
            :img-src="$baseUrl + item.img"
            img-alt="Image"
            img-top
            tag="article"
            style="max-width: 20rem;"
            class="m-1 p-0"
          >
            <b-card-text>{{ item.body.fr }}</b-card-text>
            <div
              style="position: absolute;top:0;height: 100%;width: 100%;left: 0;background: #22222277;pointer-events:all;display: block;padding: 8px;"
            >
              <b-button
                class="float-right ml-1"
                variant="info"
                @click="showEditItemModal(item, index)"
              >
                <b-icon icon="pencil-fill" aria-label="Edit"></b-icon>
              </b-button>
              <b-button class="float-right" variant="danger" @click="deleteItem(index)">
                <b-icon icon="trash-fill" aria-label="Delete"></b-icon>
              </b-button>
            </div>
          </b-card>
        </b-row>
        <b-button class="w-100 h-100" variant="info" @click="showAddModal">
          <b-icon icon="plus-lg" aria-label="Add"></b-icon>
        </b-button>
      </b-card>
      <b-modal id="section7ItemModal" :title="modalData.modalTitle" hide-footer>
        <b-form @submit.prevent="saveSection7Item">
          <NImg v-model="modalData.img" width="100px" height="100px"></NImg>
          <b-form-group label="Title">
            <b-input-group prepend="EN">
              <b-form-input v-model="modalData.title_en" />
            </b-input-group>
            <b-input-group prepend="FR" class="mt-1">
              <b-form-input v-model="modalData.title_fr" />
            </b-input-group>
          </b-form-group>
          <b-form-group label="Body">
            <b-input-group prepend="EN">
              <b-form-input v-model="modalData.body_en" />
            </b-input-group>
            <b-input-group prepend="FR" class="mt-1">
              <b-form-input v-model="modalData.body_fr" />
            </b-input-group>
          </b-form-group>
          <b-button variant="primary" type="submit" class="my-2 float-right">Save</b-button>
        </b-form>
      </b-modal>
    </div>
  </template>
  
  <script>
  import axios from "axios";
  import { getCookie } from "../../Api";
  export default {
    name: "Section_7",
    props: ["data"],
    data() {
      return {
        modalData: {}
      };
    },
    methods: {
      showAddModal() {
        this.modalData = {
          modalTitle: "Add Item"
        };
        this.$bvModal.show("section7ItemModal");
      },
      async saveEnable() {
        const p = this.$parent.$parent.$parent;
        let payload = {
          section: "section_7",
          enable: this.data.section_7.enable
        };
        p.isLoading = true;
        try {
          const res = await axios.post(
            this.$baseUrl + "/api/section_enable",
            payload,
            {
              headers: {
                secret: getCookie("secret")
              }
            }
          );
          this.$bvToast.toast("updated", {
            title: "Success",
            variant: "success",
            solid: true
          });
          p.onDataChanged(res.data);
        } catch (error) {
          p.showError(error);
        }
        p.isLoading = false;
      },
      async showEditItemModal(item, index) {
        this.modalData = {
          modalTitle: "Edit Item",
          title_en: item.title.en,
          title_fr: item.title.fr,
          body_en: item.body.en,
          body_fr: item.body.fr,
          img: item.img,
          index: index
        };
        this.$bvModal.show("section6ItemModal");
      },
      async saveSection7Item() {
        const p = this.$parent.$parent.$parent;
        const payload = {
          field: "items",
          img: this.modalData.img,
          title_en: this.modalData.title_en,
          title_fr: this.modalData.title_fr,
          body_en: this.modalData.body_en,
          body_fr: this.modalData.body_fr,
          index: this.modalData.index
        };
        p.isLoading = true;
        try {
          const res = await axios.post(
            this.$baseUrl + "/api/section_7",
            payload,
            {
              headers: {
                secret: getCookie("secret")
              }
            }
          );
          this.$bvToast.toast("updated", {
            title: "Success",
            variant: "success",
            solid: true
          });
          this.$bvModal.hide("section7ItemModal");
          p.onDataChanged(res.data);
        } catch (error) {
          p.showError(error);
        }
        p.isLoading = false;
      },
      async deleteItem(index) {
        const p = this.$parent.$parent.$parent;
        if (
          await this.$bvModal.msgBoxConfirm(
            "Please confirm that you want to delete this item.",
            {
              title: "Please Confirm",
              size: "lg",
              buttonSize: "md",
              okVariant: "danger",
              okTitle: "DELETE",
              cancelTitle: "CANCEL",
              footerClass: "p-2",
              hideHeaderClose: false,
              centered: true
            }
          )
        ) {
          let payload = {
            field: "delete_item",
            index: index
          };
          p.isLoading = true;
          try {
            const res = await axios.post(
              this.$baseUrl + "/api/section_7",
              payload,
              {
                headers: {
                  secret: getCookie("secret")
                }
              }
            );
            this.$bvToast.toast("updated", {
              title: "Success",
              variant: "success",
              solid: true
            });
            p.onDataChanged(res.data);
          } catch (error) {
            p.showError(error);
          }
          p.isLoading = false;
        }
      }
    }
  };
  </script>
  
  <style>
  </style>